.map-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  overflow: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}

.coordinates {
  background-color: rgba(35, 55, 75, 0.9);
  color: #fff;
  padding: 6px 12px;
  font-family: monospace;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  margin: 12px;
  border-radius: 4px;
}
.mapboxgl-map{
  position: relative;
  width: 100%;
  height: 100%;
}
.mapboxgl-control-container {
  display: none;
}
