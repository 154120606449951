$main_color: rgb(143, 143, 142);

.map-popup {
  z-index: 3;
  background: $main_color;
  width: fit-content;
  padding: 10px;
  font-size: 14px;
  line-height: 22px;
  font-weight: bold;
  position: relative;

  &-content {
    &-item {
      display: flex;
      align-items: center;
    }
  }
}
.map-popup {
  background: $main_color;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  max-height: 400px !important;
  overflow-x: hidden !important;
  overflow: scroll;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 3px #8a8e8d8a;
    border-radius: 50%;
  }
  &::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px #000000;
  }
}

.mapboxgl-popup {
  max-width: auto !important;

  &-content {
    padding: 0 !important;
  }

  &-tip {
    border: 18px solid transparent;
  }
  &-anchor {
    &-top,
    &-top-right,
    &-top-left {
      .mapboxgl-popup-tip {
        border-bottom-color: $main_color !important;
      }
    }

    &-bottom,
    &-bottom-right,
    &-bottom-left {
      .mapboxgl-popup-tip {
        border-top-color: $main_color !important;
      }
    }

    &-left {
      .mapboxgl-popup-tip {
        border-right-color: $main_color !important;
      }
    }
    &-right {
      .mapboxgl-popup-tip {
        border-left-color: $main_color !important;
      }
    }
  }
}
