.drawer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(35, 55, 75, 0.9);
  color: #fff;
  padding: 6px 12px;
  font-family: monospace;
  z-index: 1;
  position: absolute;
  top: 5%;
  left: 0;
  margin: 12px;
  border-radius: 4px;
  min-width: 400px;
  padding: 20px;
  &-title {
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 10px;
    border-bottom: 1px solid #ccc;
  }

  &-filters {
    &-item {
      display: flex;
      justify-content: space-between;
      color: #fff;
      min-width: 350px;
      align-items: center;
      &-title {
        width: 80px;
        text-transform: capitalize;
      }
    }
  }
}
